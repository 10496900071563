import { useStore } from '../../store/useStore';
import { STRIPE_CONFIG } from './config';
import type { CheckoutResult } from './config';

export const initiateCheckout = async (email?: string): Promise<CheckoutResult> => {
  const store = useStore.getState();

  try {
    if (!email) {
      throw new Error('Email is required for checkout');
    }

    // Save upgrade intent in store
    await store.setUpgradeIntent('pro');

    store.addNotification({
      title: 'Starting Checkout',
      message: 'Redirecting to secure payment page...',
      type: 'info'
    });

    // Construct checkout URL with query parameters
    const params = new URLSearchParams({
      email,
      success_url: `${window.location.origin}/payment-success?upgrade=success&plan=pro&intent=${store.upgradeIntent}`,
      cancel_url: `${window.location.origin}/pricing?canceled=true`,
      price: STRIPE_CONFIG.priceId
    });

    const checkoutUrl = `${STRIPE_CONFIG.checkoutUrl}?${params.toString()}`;
    window.location.href = checkoutUrl;

    return { success: true };
  } catch (err) {
    const error = {
      type: 'checkout_error',
      message: err instanceof Error ? err.message : 'Failed to start checkout process'
    };

    store.addNotification({
      title: 'Checkout Error',
      message: error.message,
      type: 'error'
    });

    return { success: false, error };
  }
};

export const handlePaymentSuccess = async () => {
  const store = useStore.getState();
  const { userData } = store;

  if (!userData) {
    throw new Error('No user data found when handling successful payment');
  }

  try {
    // Update the user's plan in Supabase
    await store.updateUserPlan('pro');
    
    // Clear any upgrade intent
    await store.clearUpgradeIntent();
    
    store.addNotification({
      title: 'Welcome to Pro!',
      message: 'Your account has been upgraded successfully. You now have access to unlimited data sources.',
      type: 'success'
    });
  } catch (err) {
    console.error('Error updating plan after payment:', err);
    store.addNotification({
      title: 'Plan Upgrade Error',
      message: 'There was an issue upgrading your plan. Please contact support.',
      type: 'error'
    });
    throw err;
  }
};